import React, { useState, useEffect, useRef } from "react";
import ReactHowler from "react-howler";
import axios from "axios";

import Highlight, { StatValue, Countdown } from "./Highlight";
import { Member, MemberError } from "./Member";
import Spinner from "./Spinner";

import audioPetter from "./assets/audio/petter.mp4";
import audioBacon from "./assets/audio/bacon.mp4";
import logo from "./assets/img/logo_light.png";
import "./App.css";

const useDelayedRender = (delay) => {
  const [delayed, setDelayed] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => setDelayed(false), delay);
    return () => clearTimeout(timeout);
  }, [delay]);
  return (fn) => !delayed && fn();
};

function App() {
  const [stats, setStats] = useState([]);
  const delayedRender = useDelayedRender(250);
  const refBacon = useRef(null);

  useEffect(() => {
    let ignore = false;

    async function fetchData() {
      const result = await axios("/api/stats");
      if (!ignore) setStats(result.data);
    }

    fetchData();
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div className="App" onClick={() => refBacon.current.howler.play()}>
      <ReactHowler src={audioPetter} playing loop />
      <ReactHowler src={audioBacon} playing={false} preload ref={refBacon} />
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      {!stats.length ? (
        delayedRender(() => <Spinner className="App-spinner" />)
      ) : (
        <>
          <section className="App-highlights">
            <Highlight title="Total kills">
              <StatValue data={stats} stat="kills" aggregator="sum" />
            </Highlight>
            <Highlight title="Average K/D">
              <StatValue data={stats} stat="kdRatio" aggregator="avg" />
            </Highlight>
            <Highlight title="Total wins">
              <StatValue data={stats} stat="wins" aggregator="sum" />
            </Highlight>
            <Highlight title="The Return of Beastwood">
              <Countdown date="2021-01-07T18:00:00" />
            </Highlight>
          </section>
          <section className="App-members">
            {stats.map((member, index) =>
              member.errors ? (
                <MemberError key={index} {...member.errors[0]} />
              ) : (
                <Member
                  key={member.data.platformInfo.platformUserHandle}
                  data={member.data}
                />
              )
            )}
          </section>
        </>
      )}
    </div>
  );
}

export default App;
