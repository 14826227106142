import "./Member.css";
import { FaLock } from "react-icons/fa";

const trackerUrl = (handle) =>
  `https://cod.tracker.gg/warzone/profile/battlenet/${encodeURIComponent(
    handle
  )}/overview`;

export function Member(props) {
  const { platformInfo } = props.data;
  const handle = platformInfo.platformUserHandle;
  const username = handle.split("#")[0];
  const brSegments = props.data.segments.filter(
    (segment) => segment.type === "mode" && segment.attributes.mode === "br"
  );
  const stats = brSegments[0].stats;

  return (
    <div className="Member">
      <div className="Member-header">{username}</div>
      <div className="Member-property">
        <strong>Kills</strong>
        <div>{stats.kills.displayValue}</div>
      </div>
      <div className="Member-property">
        <strong>Deaths</strong>
        <div>{stats.deaths.displayValue}</div>
      </div>
      <div className="Member-property">
        <strong>Downs</strong>
        <div>{stats.downs.displayValue}</div>
      </div>
      <div className="Member-property">
        <strong>K/D</strong>
        <div>{stats.kdRatio.displayValue}</div>
      </div>
      <div className="Member-property">
        <strong>Wins</strong>
        <div>{stats.wins.displayValue}</div>
      </div>
      <div className="Member-property">
        <strong>Games Played</strong>
        <div>{stats.gamesPlayed.displayValue}</div>
      </div>
      <div className="Member-property">
        <a href={trackerUrl(handle)} target="_blank" rel="noopener noreferrer">
          More stats
        </a>
      </div>
    </div>
  );
}

function ErrorIcon({ code }) {
  const mapping = { "CollectorResultStatus::Private": <FaLock /> };
  return mapping[code] || null;
}

export function MemberError({ code, message }) {
  return (
    <div className="Member Member-error">
      <ErrorIcon code={code} />
      &nbsp;{message}
    </div>
  );
}
