import "./Spinner.css";

function Spinner(props) {
  return (
    <div className={`Spinner ${props.className || ""}`}>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

export default Spinner;
