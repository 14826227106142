import ReactCountdown from "react-countdown";

import "./Highlight.css";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const aggregators = {
  sum: (values) => values.reduce((a, b) => a + b, 0),
  avg: (values) => (aggregators.sum(values) / values.length).toFixed(2),
};

export function StatValue(props) {
  const values = props.data
    .filter(({ data }) => data)
    .map(({ data }) => {
      const brSegments = data.segments.filter(
        (segment) => segment.type === "mode" && segment.attributes.mode === "br"
      );
      const stats = brSegments[0].stats;
      return stats[props.stat].value;
    });
  const value = aggregators[props.aggregator](values.length ? values : [0]);
  return numberWithCommas(value);
}

export function Countdown(props) {
  const renderer = ({ days, hours, minutes, seconds, completed }) =>
    completed ? "He's back!" : `${days}d ${hours}h ${minutes}m ${seconds}s`;
  return <ReactCountdown date={props.date} renderer={renderer} />;
}

function Highlight(props) {
  return (
    <div className="Highlight">
      <div className="Highlight-title">{props.title}</div>
      <strong className="Highlight-value">{props.children}</strong>
    </div>
  );
}

export default Highlight;
